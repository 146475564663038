import Title from "./Title";
import {Link} from "react-router-dom";
import styles from './ProductList.module.css'
import QuantityBtn from "./QuantityBtn";
import { CartContext } from "./CartContext";
import { useContext } from "react";

export default function Checkout() {
    let {cartItems} = useContext(CartContext)
    
    // destructure
    //let {cartItems} = cartItem

    let cartEmpty = cartItems.length<=0 ? true : false

    let grandTotal = cartItems.reduce((total, product) => {
        return total += product.price*product.quantity
    },0)
    const freeShippingPrice = 99

    return (
        <>
            <Title mainTitle="Your Shopping Cart" />
            {
                cartEmpty &&
                <div>
                    <div className="nothingInCart">
                        No Item in the shopping cart<br/><br/>
                        <Link to="/">Back to Home</Link>
                    </div>
                </div>
            }
            {
                !cartEmpty &&
                <div className="container">
                    <div className="cartSeaction">
                        <table className="checkoutTable">
                            <tbody>
                                {
                                    /* product list */
                                    cartItems.map(product => (
                                        <tr key={product.id}>
                                            <td>
                                                <Link to={'/product/'+product.id}>
                                                    <img className={styles.productImage} src={process.env.PUBLIC_URL+'/image/'+product.image} />
                                                </Link>
                                            </td>
                                            <td>
                                                <p>Name: {product.name}</p>
                                                <p>Price: ${product.price}</p>
                                                <p>Description: {product.description}</p>
                                            </td>
                                            <td width="200">
                                                <QuantityBtn productInfo={product}/>
                                            </td>
                                            <td>
                                                <div className="productSubTotal">
                                                    ${product.price*product.quantity}
                                                </div>
                                            </td>
                                        </tr>  
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="checkoutSection">
                        {/* total price */}
                        <div>Total Product</div>
                        <div className="grandTotal">${grandTotal}</div>
                        {   
                            /* fee delivery */
                            grandTotal >= freeShippingPrice ?
                            <div className="freeShipping">Free Shipping provided</div> :
                            <div className="noShipping">$99 for free shipping<br/>
                            buy more ${freeShippingPrice-grandTotal}</div>
                        }
                        <button>Checkout</button>
                    </div>
                </div>
            }
        </>
    )
}