import { useState, useEffect } from "react"
import {useParams, Link} from "react-router-dom"
import QuantityBtn from "./QuantityBtn"
import Title from "./Title"

export default function ProductDetail() {
    let params = useParams()

    let [productDetail, setProductDetail] = useState(null)

        //useEffect
    useEffect(() => {
        //1. No other 參數: component render everytime
        //2. When the dependency array is empty: will active when the page render at the first time (this case)
        //3. When the dependecy array is 變數: will active when render the  page at the first time + 指定的變數

        //use API endpiont to get data
        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            .then(data => {
                let productInfo = data.find((element) => {
                    return element.id ===parseInt(params.id)
                })
                setProductDetail(productInfo)
            })
    },[]) // <== Dependency Array

    return (
        <div>
            {
                productDetail && 
                <div className="ProductDetail">
                    <Title mainTitle={productDetail.name+"Product Detail"} />
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td align="right">
                                    <img src={process.env.PUBLIC_URL + '/image/' + productDetail.image} alt={productDetail.name} width="400" />
                                </td>
                                <td width="45%" padding="10">
                                    <p>Name: {productDetail.name}</p>
                                    <p>Price: ${productDetail.price}/unit</p>
                                    <p>Description: {productDetail.description}</p><br/>
                                    <QuantityBtn productInfo={productDetail}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }

            <Link to="/">
                <div className="backToGoodsListBtn">Back to Home</div>
            </Link>
        </div>
    )
}