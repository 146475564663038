import { useContext, useState } from "react"
import { CartContext } from "./CartContext"

export default function QuantityBtn({productInfo}) {
    const {cartItems, setCartItems} = useContext(CartContext)

    //is there has the product in the shopping cart
    let productIndexInCart = cartItems.findIndex((element) => {
        return element.id === productInfo.id
    })
    //findIndex
    //if there has the product in shopping cart -> return the index in the 'cartItems'
    //if there has no product in shopping cart -> return -1

    let [numInCart, setNumInCart] = useState(
        (productIndexInCart===-1) ? 0 : cartItems[productIndexInCart].quantity
    )
    const handleAdd = () => {
        if(productIndexInCart===-1){
            //本身not in the shopping cart, add a new element (object) to cartItems array
            setCartItems([
                {
                    id: productInfo.id,
                    name: productInfo.name,
                    image: productInfo.image,
                    price: productInfo.price,
                    description: productInfo.description,
                    quantity: 1
                }, ...cartItems]
            )
        } else {
            //本身has the product in the shopping cart, add quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity++
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart+1)
    }

    const handleSubtract = () => {
        if (cartItems[productIndexInCart].quantity===1) {
            //remove object
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart, 1)
            setCartItems(newCartArray)
        } else {
            //deduct quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart-1)
    }
    return (
        <div className="addToCart">
            {
                (numInCart === 0) ?
                <span className="addToCartBtn" onClick={handleAdd}>Add To Shopping Cart</span> :
                <div>
                    <span className="subtractBtn" onClick={handleSubtract}>-</span>
                    {numInCart} units
                    <span className="addBtn" onClick={handleAdd}>+</span>
                </div>
            }
        </div>
    )
}