import {Link} from "react-router-dom"
import styles from './ProductList.module.css'
import React, {useState, useEffect} from "react"  //react Hook
import Title from "./Title"
import QuantityBtn from "./QuantityBtn"

export default function ProductList() {

    /*
    const productList = [
        {"id":1, "name":"Apple", "price":5, "image":"apple.jpg", "description":"Fresh apple 50g, good for yuor health"},
        {"id":2, "name":"Orange", "price":3, "image":"orange.jpg", "description":"Fresh orange 50g, so sweet"},
        {"id":3, "name":"Mango", "price":4, "image":"mango.jpg", "description":"Fresh mango 500g, good for make dessert"},
        {"id":4, "name":"Watermelon", "price":20, "image":"watermelon.jpg", "description":"Fresh watermelon 2kg, summer essential"},
        {"id":5, "name":"Blueberry", "price":10, "image":"blueberry.jpg", "description":"Fresh blueberry 50g, good for your eyes"},
        {"id":6, "name":"White Carrot", "price":5, "image":"white-carrot.jpg", "description":"Fresh white carrot 1kg, good for make soup"},
    ]
    */
   let [productList, setProductList] = useState([])

  /* console.log(productList) */

    //useEffect
    useEffect(() => {
        //1. No other 參數: component render everytime
        //2. When the dependency array is empty: will active when the page render at the first time (this case)
        //3. When the dependecy array is 變數: will active when render the  page at the first time + 指定的變數

        //use API endpiont to get data
        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(response => response.json())
            .then(data => setProductList(data))

        console.log(productList)
    },[]) // <== Dependency Array

    return (
        //React Fragment簡寫
        <>
            <Title mainTitle="React Fruit Shop" />
            <div className="container">
                {
                    productList.map(product => (
                        <React.Fragment key={product.id}>
                            <div className="containerItem">
                                <Link to={"/product/"+product.id}>
                                    <img className={styles.productImage} src={process.env.PUBLIC_URL + '/image/' + product.image} alt={product.name} />
                                </Link>
                                <div className="productName">
                                    {product.name} - ${product.price}/unit
                                </div>
                                <QuantityBtn productInfo={product} />
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>
        </>
    )
}