
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import ProductList from './ProductList';
import Checkout from './Checkout';
import ProductDetail from './ProductDetail';
import { CartContext } from './CartContext';
import { useState } from 'react';

function App() {

  const [cartItems, setCartItems] = useState([])

  return (
    <BrowserRouter>
      <CartContext.Provider value={{cartItems, setCartItems}}>
        <nav>
          <Link to="/">Home</Link>
          <Link to="/checkout">Shopping Cart</Link>
        </nav>
        <Routes>
          <Route path="/" element={<ProductList/>} />
          <Route path="/checkout" element={<Checkout/>} />

          <Route path="/product" element={<ProductDetail/>}>
            <Route path=":id" element={<ProductDetail/>} />
          </Route>

          <Route path="*" element={<p>Cannot find the page</p>} />
        </Routes>
      </CartContext.Provider>
    </BrowserRouter>
  );
}

export default App;

//cmd "npm run start"
//install react-router-dom "npm i react-router-dom" https://v5.reactrouter.com/web/guides/quick-start